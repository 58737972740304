.small-loading-roller {
    border: 2px solid red;
    border-top: 2px solid green;
    border-bottom: 2px solid green;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }