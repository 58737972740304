@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-height {
    height: calc((100vh - 3.5rem));
    max-height: calc((100vh - 3.5rem));
}

.hide-scrollbar-conversation::-webkit-scrollbar {
    display: none;
}