.fetch-loading-roller {
    /* display: none; */
    border: 2px solid orange;
    border-top: 2px solid #3498db;
    border-bottom: 2px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }