.conversation-container {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.bot-message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.user-message {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.message-content {
    background-color: #fff;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 70%;
}
