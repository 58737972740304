@tailwind base; 
@tailwind components;
@tailwind utilities;

.custom-shape-divider-bottom-1712904230 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1712904230 svg {
    position: relative;
    display: block;
    width: calc(174% + 1.3px);
    height: 88px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1712904230 .shape-fill {
    fill: #FFFFFF;
}