.loading-roller {
    /* display: none; */
    border: 2px solid black;
    border-top: 2px solid #e85b1a;
    border-bottom: 2px solid #e85b1a;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }