/* card-author-appstyles */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 0;
}

.card-author-app {
  position: relative;
  width: 300px;
  height: 400px;
  margin: 20px;
  overflow: hidden;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s, box-shadow 0.5s;
}

.card-author-app .imgBx img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

.card-author-app .content {
  position: absolute;
  bottom: -160px;
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  flex-direction: column;
  backdrop-filter: blur(15px);
  border-radius: 15px;
  transition: bottom 0.5s, background-color 0.5s, box-shadow 0.5s;
}

.card-author-app .content {
  bottom: 0;
}

.card-author-app .content .contentBx h3 {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin: 20px 0 15px;
  line-height: 1.1em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(-20px);
}

.card-author-app .content .contentBx h3 {
  opacity: 1;
  transform: translateY(0);
}

.card-author-app .content .contentBx h3 span {
  font-size: 12px;
  font-weight: 300;
  text-transform: initial;
}

.card-author-app .content .sci {
  position: relative;
  bottom: 10px;
  display: flex;
}

.card-author-app .content .sci li {
  list-style: none;
  margin: 0 10px;
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
  transition-delay: calc(0.2s * var(--i));
}

.card-author-app .content .sci li {
  transform: translateY(0);
  opacity: 1;
}

.card-author-app .content .sci li a {
  color: white;
  font-size: 24px;
}

/* Glowing effect */
.glow-box {
  animation: glow-box 1.5s ease-in-out infinite alternate;
  text-shadow: 0 10px 10px rgba(255, 255, 255, .5);
  color: #fff;
}