@tailwind base;
@tailwind components;
@tailwind utilities;

.side-bar-close {
    animation: close-bar 1s ease-in-out 0s 1 normal forwards;
}

@keyframes close-bar {
    0% {
        margin-left: 0;
        margin-right: 0;
    }
    100% {
        margin-left: -240px;
        margin-right: 240px;
    }
}
