@keyframes glow-animation {
    0% {
        box-shadow: 
            0 0 1px rgba(255, 255, 0, 0.5),
            0 0 2px rgba(0, 0, 255, 0.7),
            0 0 3px rgba(128, 0, 128, 0.9),
            0 0 4px rgba(0, 255, 255, 1),
            0 0 5px rgba(255, 255, 0, 0.5),
            0 0 6px rgba(255, 255, 0, 0.7),
            0 0 7px rgba(255, 255, 0, 0.9),
            0 0 8px rgba(255, 255, 0, 1);
    }
    50% {
        box-shadow: 
            0 0  3px rgba(255, 255, 0, 0.5),
            0 0  4px rgba(0, 0, 255, 0.7),
            0 0  5px rgba(128, 0, 128, 0.9),
            0 0  6px rgba(0, 255, 255, 1),
            0 0  7px rgba(255, 255, 0, 0.5),
            0 0  8px rgba(255, 255, 0, 0.7),
            0 0  9px rgba(255, 255, 0, 0.9),
            0 0  10px rgba(255, 255, 0, 1);
    }
    100% {
        box-shadow: 
            0 0px 1px rgba(255, 255, 0, 0.5),
            0 0px 2px rgba(0, 0, 255, 0.7),
            0 0px 3px rgba(128, 0, 128, 0.9),
            0 0px 4px rgba(0, 255, 255, 1),
            0 0px 5px rgba(255, 255, 0, 0.5),
            0 0px 6px rgba(255, 255, 0, 0.7),
            0 0px 7px rgba(255, 255, 0, 0.9),
            0 0px 8px rgba(255, 255, 0, 1);
    }
}



.glow {
    color: #fff;
    animation: glow-animation 2s infinite alternate;
}


.btn {
    border: #683FEA 2px solid;
    border-radius: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background: #1C1A1C;
    cursor: pointer;
    transition: all 450ms ease-in-out;
  }
  
  .sparkle {
    fill: #AAAAAA;
    transition: all 800ms ease;
  }
  
  .text {
    font-weight: 600;
    color: #AAAAAA;
    font-size: medium;
  }
  
  .btn:hover {
    background: linear-gradient(0deg,#A47CF3,#683FEA);
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4),
    inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 4px rgba(255, 255, 255, 0.2),
    0px 0px 180px 0px #9917FF;
    transform: translateY(-2px);
  }
  
  .btn:hover .text {
    color: white;
  }
  
  .btn:hover .sparkle {
    fill: white;
    transform: scale(1.2);
  } 