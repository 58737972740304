@tailwind base;
@tailwind components;
@tailwind utilities;


.custom-shape-divider-bottom-1712495023 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1712495023 svg {
    position: relative;
    display: block;
    width: calc(265% + 1.3px);
    height: 196px;
}

.custom-shape-divider-bottom-1712495023 .shape-fill {
    fill: #FFFFFF;
}