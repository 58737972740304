@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Dosis-Medium' ;
    src: local('DosisMedium') , url('./Fonts/Dosis-Medium.ttf');
}
.navbar-text-custom {
    font-family: 'Dosis-Medium';
    letter-spacing: 0.1em;
    @apply font-bold lg:text-[1.2rem] sm:text-base text-xl;
}

.blurred-navbar {
    @apply rounded-b-md;
    backdrop-filter: blur(8px);
}


/* Add this CSS for the transition effect */
