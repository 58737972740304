@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Dosis-semibold';
    src: local('Dosis-semibold') , url('./Fonts/Dosis-SemiBold.ttf');
}

@font-face {
    font-family: 'Dosis-regular';
    src:local('Dosis-regular') , url('./Fonts/Dosis-Medium.ttf');
}

@font-face {
    font-family: 'Sanlabello';
    src: local('Sanlabello') , url('./Fonts/Sanlabello.ttf');
}

.head-text-example {
    font-family: 'Dosis-semibold';
    @apply text-[2rem] sm:text-[2.5rem] md:text-[3rem] lg:text-[3.5rem] xl:text-[4rem];
}

.landing-normal-body-text-demo {
    font-family: 'Dosis-regular';
}

.example-body-text {
    font-family: 'Sanlabello';
}

